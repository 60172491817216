<li class="dropdown dropdown-submenu office-name-container">
  <ng-container>
    <div class="dropdown-toggle dropdown-item office-name">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </div>
    <ng-template #itemContent>
      <span class="menu-title text-truncate">
        {{ currentUser.companyDetail.officeName }}
      </span>
    </ng-template>
    <ul
      class="dropdown-menu dropdown-menu-right office-list"
      [ngClass]="{ show: isShow }"
    >
      <ng-container *ngFor="let office of offices">
        <li
          class="office-item"
          [ngClass]="office._id === currentUser._id ? 'selected-office' : ''"
          (click)="onSelectOffice(office._id)"
        >
          {{ office.companyDetail.officeName }}
        </li>
      </ng-container>
    </ul>
  </ng-container>
</li>
