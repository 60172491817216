<div
  fxLayoutAlign="center center"
  fxLayout="column"
  style="width: 100%; padding: 40px"
>
  <h4 class="title">{{ title | translate }}</h4>
  <h4 class="title">{{ content_message | translate }}</h4>
  <div fxFlex fxLayout="row" style="margin-top: 25px">
    <button class="warning-nav-btn" (click)="cancelExit()">
      {{ text_btn_cancel | translate }}
    </button>
    <button class="logout" (click)="confirmExit()">
      {{ text_btn_accept | translate }}
    </button>
  </div>
</div>