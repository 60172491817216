<button
  type="button"
  style="white-space: nowrap"
  class="btn btn-width open-modal-btn"
  (click)="open()"
  rippleEffect
>
  {{ "copy_and_create_another_job" | translate }}
</button>
<ng2-flatpickr #startDatePicker id="datePicker1"></ng2-flatpickr>
<ng-template #partTimeJobSelectorModal id="partTimeJobSelectorModal">
  <div class="popup-inner">
    <div class="modal-header" style="background-color: #ffff">
      <h5 class="popup-title" id="myModalLabel160">
        {{ "list_created_job" | translate }}
      </h5>
    </div>
    <div class="popup-body" tabindex="0" ngbAutofocus>
      <div style="overflow: auto; overflow-y: hidden; padding-bottom: 10px">
        <div style="background-color: #fff">
          <table id="example" class="table" style="width: 100%">
            <thead>
              <tr>
                <th>No.</th>
                <th>{{ "title" | translate }}</th>
                <th>{{ "description" | translate }}</th>
                <th>{{ "created_at" | translate }}</th>
                <th></th>
              </tr>
            </thead>
            <div
              class="d-flex justify-content-center mt-5 mb-5"
              *ngIf="isFetching"
              style="position: reactivate"
            >
              <div
                class="spinner-border"
                role="status"
                style="position: absolute; left: 50%; right: 50%"
              >
                <span class="sr-only">{{ "loading" | translate }}...</span>
              </div>
            </div>
            <tbody style="background-color: #fff" *ngIf="!isFetching">
              <tr *ngFor="let job of jobs; let i = index">
                <td>{{ (page - 1) * pageSize + i + 1 }}.</td>
                <td>{{ job.job_title }}</td>
                <td>{{ job.description }}</td>
                <td>{{ job.createdAt | date : "hh:mm MM/dd/yyyy" }}</td>
                <td>
                  <button
                    type="button"
                    style="white-space: nowrap"
                    class="btn btn-width open-modal-btn"
                    (click)="copy(job)"
                    rippleEffect
                  >
                    {{ "copy" | translate }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="!jobs.length && !isFetching" style="width: 100%">
            <h4 class="no-data">{{ "no_data" | translate }}</h4>
          </div>
        </div>
      </div>
      <pagination
        [page]="page"
        [pageSize]="pageSize"
        [totalItem]="totalJob"
        [items]="jobs"
        (onPageChange)="getPartTimeJobs($event)"
      >
      </pagination>
    </div>
    <div>
      <button
        type="button"
        class="btn popup-cancel-btn btn-width close-btn"
        rippleEffect
        (click)="close()"
      >
        {{ "close" | translate }}
      </button>
    </div>
  </div>
</ng-template>
