import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { User } from "app/auth/models";
import {
  ApiService,
  AuthenticationService,
  GeneralService,
} from "app/auth/service";
import { ApiConstant } from "app/shared/api-constents";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";

@Component({
  selector: "office-selector",
  templateUrl: "./office-selector.html",
  styleUrls: ["./office-selector.scss"],
})
export class OfficeSelectorComponent implements OnInit, OnDestroy {
  public lang;
  private unsubscribe: Subject<any>;
  isShow = false;
  offices = [];
  currentUser: any;

  constructor(
    private readonly generalService: GeneralService,
    private readonly apiService: ApiService,
    private readonly toasterService: ToastrService,
    private readonly afAuth: AngularFireAuth,
    private readonly authService: AuthenticationService
  ) {
    this.unsubscribe = new Subject();
    this.authService.currentUser.subscribe((x) => (this.currentUser = x));
    this.offices = this.currentUser.careProviders || [];
  }

  ngOnInit(): void {
    this.generalService.currentLanguage.subscribe((lang) => {
      this.lang = lang;
    });
    this.generalService.setLanguage();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  @HostListener("mouseenter")
  show(): void {
    this.isShow = true;
  }

  @HostListener("mouseleave")
  hide(): void {
    this.isShow = false;
  }

  onSelectOffice(officeId) {
    this.apiService
      .ExecutePut(this.apiService.baseUrl + ApiConstant.office.switch, {
        officeId,
      })
      .subscribe(
        async (response: any) => {
          const customToken = response.result;
          const data = await this.afAuth.signInWithCustomToken(customToken);

          this.authService.setTokenExpiryTime();
          this.generalService.setToken =
            data?.user?.multiFactor["user"]?.accessToken;
          this.generalService.setRefreshToken =
            data?.user?.multiFactor["user"]?.stsTokenManager?.refreshToken;
          this.generalService.setuid = data?.user?.multiFactor["user"]?.uid;

          window.location.reload();
        },
        (error: any) => {
          this.toasterService.error(error.message);
        }
      );
  }
}
