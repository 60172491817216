import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { APP_VERSION } from "../constant/app-version.constant";

@Injectable({
  providedIn: "root",
})
export class VersionCheckService {
  constructor(private swUpdate: SwUpdate) {
    console.info(`App version: ${APP_VERSION}`);

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe(() => {
        console.log("⚡ New version available! Reloading...");
        window.location.reload(); // Force refresh to load the latest version
      });

      // Check for updates every 30 minutes
      setInterval(async () => {
        try {
          console.log("🔍 Checking for updates...");
          await this.swUpdate.checkForUpdate();
        } catch (e) {
          console.error("❌ Update check failed:", e);
        }
      }, 30 * 60 * 1000); // 30 minutes
    }
  }
}
