import { HttpParams } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService, GeneralService } from "app/auth/service";
import { ApiConstant } from "app/shared/api-constents";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "part-time-job-selector",
  templateUrl: "./part-time-job-selector.component.html",
  styleUrls: ["./part-time-job-selector.component.scss"],
})
export class PartTimeJobSelectorComponent implements OnInit, OnDestroy {
  public lang;
  private unsubscribe: Subject<any>;
  private page: number = 1;
  private pageSize: number = 10;
  jobs: any[] = [];
  totalJob: number = 0;
  isFetching: boolean = false;
  @ViewChild("partTimeJobSelectorModal") modal: ElementRef;

  @Output() onCopyJob = new EventEmitter<any>();

  constructor(
    private generalService: GeneralService,
    private apiService: ApiService,
    private toaster: ToastrService,
    private modalService: NgbModal
  ) {
    this.unsubscribe = new Subject();
  }

  ngOnInit(): void {
    this.generalService.currentLanguage.subscribe((lang) => {
      this.lang = lang;
    });
    this.generalService.setLanguage();
    this.getPartTimeJobs(this.page);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getPartTimeJobs(page: number) {
    this.isFetching = true;
    this.jobs = [];
    const query = new HttpParams({
      fromObject: {
        page,
        pageSize: this.pageSize,
      },
    });

    this.apiService
      .ExecuteGet(
        this.apiService.baseUrl + ApiConstant.jobTemplate.list,
        null,
        query
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (response: any) => {
          this.jobs = response.result.jobTemplates;
          this.totalJob = response.result.counter.total;
          this.isFetching = false;
        },
        (error) => {
          this.toaster.error(error?.error?.message);
          this.isFetching = false;
        }
      );
  }

  open() {
    this.modalService.open(this.modal, {
      centered: true,
      size: "lg",
      windowClass: "modal modal-dark",
      backdrop: "static",
      keyboard: false,
    });
  }

  close() {
    this.modalService.dismissAll();
  }

  copy(job) {
    this.onCopyJob.emit(job);
    this.close();
  }
}
