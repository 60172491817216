import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { breakTimeData, caregivingItemsData, careServicesData, qualificationData, text_belogings_placeholder, text_caution_placeholder, text_daily_flow_placeholder, text_job_description_placeholder, transportationData, TreatmentData } from 'app/shared/constant/job.constant';
import { IJobFormTemplate } from 'app/shared/interface/job.interface';

@Component({
  selector: 'app-job-templete-form',
  templateUrl: './job-templete-form.component.html',
  styleUrls: ['./job-templete-form.component.scss']
})
export class JobTemplateFormComponent implements OnInit {

  careServices = [];
  breakTime = [];
  caregivingItems = [];
  Treatment = [];
  qualification = [];
  transportation = [];
  job_description_placeholder: string = "";
  daily_flow_placeholder: string = "";
  belogings_placeholder: string = "";
  caution_placeholder: string = "";
  multipleJobError: any;
  selectedServices: string[] = [];
  assistanceItems: string[] = [];
  selectedTreantment: string[] = [];
  selectedQualification: string[] = [];
  selectedTransportation: string[] = [];

  @Input() submitTitle: string = 'common.create';

  @Input() 
  set selectedServicesData(value: string[]){
    this.selectedServices = value;
  };

  @Input() 
  set assistanceItemsData(value: string[]){
    this.assistanceItems = value;
  };

  @Input() 
  set selectedTreantmentData(value: string[]){
    this.selectedTreantment = value;
  };

  @Input()
  set selectedQualificationData(value: string[]){
    this.selectedQualification = value;
  };

  @Input()
  set selectedTransportationData(value: string[]){
    this.selectedTransportation = value;
  };
  @Input() formBuilder: FormGroup
  @Input() submitted: boolean = false;
  @Output() payloadForm = new EventEmitter<IJobFormTemplate>();

  get formControls() {
    return this.formBuilder.controls;
  }
  constructor() {
  }


  ngOnInit(): void {
    this.careServices = careServicesData;
    this.breakTime = breakTimeData;
    this.caregivingItems = caregivingItemsData;
    this.Treatment = TreatmentData;
    this.qualification = qualificationData;
    this.transportation = transportationData;
    this.job_description_placeholder = text_job_description_placeholder;
    this.daily_flow_placeholder = text_daily_flow_placeholder;
    this.belogings_placeholder = text_belogings_placeholder;
    this.caution_placeholder = text_caution_placeholder;

  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  numberValidation(value, type?: string) {
    if (type == "transportation") {
      this.formBuilder.patchValue({
        payment_amount: value.replace("円", "") + "円",
      });
    } else if (type === "deadline_day") {
      this.formBuilder?.patchValue({
        application_deadline_day_before_start_date:
          value.replace("日", "") + "日",
      });
    } else {
      if (value) {
        this.formBuilder?.patchValue({ hourly_wage: value.replace("円", "") + "円" });
      } else {
        this.formBuilder?.patchValue({ hourly_wage: "0円" });
      }
    }
  }

  expenseToggle(event) {
    if (event?.target?.value == "no") {
      this.formBuilder.patchValue({
        payment_amount: "0円",
      });
    }
  }

  submit() {
    let transportation = this.formBuilder.value?.transpor_expence == "yes" ? true : false;
    let transportation_payment = this.formBuilder.value.payment_amount?.includes("円")
      ? this.formBuilder.value?.payment_amount.replace("円", "")
      : this.formBuilder.value?.payment_amount;
    const application_deadline_day_before_start_date =  !!this.formBuilder.value?.application_deadline_day_before_start_date && this.formBuilder.value?.application_deadline_day_before_start_date.includes("日")
                                                        ? this.formBuilder.value?.application_deadline_day_before_start_date.replace(
                                                          "日",
                                                          ""
                                                        )
                                                        : this.formBuilder.value?.application_deadline_day_before_start_date;

    const hourly_wage = this.formBuilder.value?.hourly_wage && this.formBuilder.value?.hourly_wage.includes("円")
                                                        ? this.formBuilder.value?.hourly_wage?.replace("円", "")
                                                        : this.formBuilder.value?.hourly_wage
    const payload: IJobFormTemplate = {
      title: this.formBuilder.value.title,
      description: this.formBuilder.value?.description,
      job_title: this.formBuilder.value?.recruitment_title,
      careType: this.selectedServices,
      applicant_numbers: this.formBuilder.value?.number_of_applicance,
      // daily_salary: this.formBuilder.value?.daily_wages,
      transportation_expenses: transportation,
      payment_amount: transportation ? parseInt(transportation_payment) : 0,
      care_items: this.assistanceItems,
      daily_flow: this.formBuilder.value?.work_flow,
      treatment: this.selectedTreantment,
      qualifications: this.selectedQualification,
      to_bring: this.formBuilder.value?.what_to_bring,
      transportation: this.selectedTransportation,
      precautions: this.formBuilder.value?.caution,
      smoking_prevention_measure: this.formBuilder.value?.smokking_condition,
      hourly_wage: hourly_wage,
      application_deadline_day_before_start_date:application_deadline_day_before_start_date
    };

    this.payloadForm.emit(payload)
  }

}
