import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { GoogleMapsModule } from "@angular/google-maps";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { NgSelectModule } from "@ng-select/ng-select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { ProfileWarningComponent } from "./components/profile-warning/profile-warning.component";
import { QrCodeModalComponent } from "./components/qr-code-modal/qr-code-modal.component";
import { QRCodeModule } from "angularx-qrcode";
import { LogoutComponent } from "./components/logout/logout.component";
import { NgOtpInputModule } from "ng-otp-input";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { InterviewResultReportComponent } from "./components/interview-result-report/interview-result-report.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { JobPreviewComponent } from "./components/job-preview/job-preview.component";
import { PartTimeJobSelectorComponent } from "./components/part-time-job-selector/part-time-job-selector.component";
import { PaginationComponent } from "./components/pagination/pagination";
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { JobTemplateFormComponent } from "./components/job-templete-form/job-templete-form.component";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "../../assets/i18n/", ".json");
}
let language = localStorage.getItem("Language");
@NgModule({
  declarations: [
    ProfileWarningComponent,
    QrCodeModalComponent,
    LogoutComponent,
    InterviewResultReportComponent,
    JobPreviewComponent,
    PartTimeJobSelectorComponent,
    PaginationComponent,
    ConfirmModalComponent,
    JobTemplateFormComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      defaultLanguage: language ? language : "ja",
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    FlexLayoutModule,
    GoogleMapsModule,
    NgSelectModule,
    MatDatepickerModule,
    QRCodeModule,
    NgOtpInputModule,
    NgbModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2FlatpickrModule,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    GoogleMapsModule,
    NgSelectModule,
    MatDatepickerModule,
    ProfileWarningComponent,
    QrCodeModalComponent,
    LogoutComponent,
    QRCodeModule,
    NgOtpInputModule,
    NgbModule,
    CKEditorModule,
    InterviewResultReportComponent,
    Ng2FlatpickrModule,
    JobPreviewComponent,
    PartTimeJobSelectorComponent,
    PaginationComponent,
    JobTemplateFormComponent,
    ConfirmModalComponent
  ],
})
export class SharedModule {}

