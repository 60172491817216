const careServicesData: string[] = [
    "介護付き有料老人ホーム",
    "住宅型有料老人ホーム",
    "通所介護・デイサービス",
    "特別養護老人ホーム",
    "介護老人保健施設",
    "ケアハウス",
    " ショートステイ",
    "デイケア",
    "グループホーム",
    "小規模多機能居宅介護",
    "看護小規模多機能居宅介護",
    "障がい者支援",
    "病院",
    "その他",
];

const breakTimeData = ["休憩なし", "15分", "30分", "45分", "60分", "90分", "120分"];
const caregivingItemsData = [
    "清掃",
    "レクリエーション",
    "リネン交換",
    "入浴介助",
    "排泄介助",
    "体位変換",
    "バイタル測定",
    "服薬管理・指導",
    "介護記録",
    "着脱介助",
    "配膳下膳",
    "口腔ケア",
    "夜勤",
    "送迎",
    "食事介助",
    "移動介助",
    "対話・見守り",
    "起床・就寝介助",
    "該当なし",
]

const TreatmentData = [
    "車通勤OK",
    "自転車通勤OK",
    "制服貸与あり",
    "髪型・カラー自由",
    "服装自由",
    "鍵付きロッカーあり",
];
const qualificationData = [
    "介護福祉士",
    "実務者研修",
    "初任者研修",
    "ヘルパー1級",
    "ヘルパー2級",
    "介護職員基礎研修",
    "看護師",
    "准看護師",
    "作業療法士",
    "理学療法士",
    "言語聴覚士",
    "介護支援専門員",
    "社会福祉士",
    "社会福祉主事",
    "柔道整復師",
    "福祉用具専門相談員",
    "移動介護従事者",
    "認知症ケア専門士",
    "レクリエーション介護士1級",
    "レクリエーション介護士2級",
    "栄養士",
    "管理栄養士",
    "介護食士1級",
    "介護食士2級",
    "介護食士3級",
    "精神保健福祉士",
    "認知症介護基礎研修",
    "同行援護従業者養成研修一般課程",
    "同行援護従業者養成研修応用課程",
    "重度訪問介護従業者養成研修基礎課程",
    "重度訪問介護従業者養成研修追加課程",
    "重度訪問介護従業者養成研修統合課程",
    "喀痰吸引等研修第1号研修",
    "喀痰吸引等研修第2号研修",
    "はり師",
    "きゅう師",
];
const transportationData = ["徒歩", "電車", "バス", "車", "バイク"];
const text_job_description_placeholder: string =
    "デイサービスでのお仕事をお願いいたします。\n主な仕事内容は下記の通りです。\n\n・送迎の迎え入れ、送り出しの手伝い\n・入浴解介助、お着替えのお手伝い\n・トイレ介助\n・レクリエーションフォロー";
const text_daily_flow_placeholder: string =
    "■ 9:00\n・出勤：玄関のインターホンを押して頂き、「もん助でお仕事のために来ました」と伝える。\n・QRコードをスキャンし、出勤処理を行います。\n\n ■ 9:00 - 10:00 \n・施設に到着した送迎車より、ご利用者様を介助して施設内にご案内して頂きます。\n\n■ 10:00 - 12:00\n・入浴、お着替えの手伝い：他のスタッフと一緒にご利用者様の入浴介助、お着替えのお手伝いをして頂きます。\n・トイレ介助：利用者様の要望に合わせて適宜トイレ移動、排泄介助を行います。\n\n■ 12:00 - 13:00\n・昼食準備 / 食事介助 / ⼝腔ケア\n・昼食の準備や⼝腔ケア、食事介助を行います。\n■ 13:00 - 15:00\n・レクリエーション活動, 機能訓練\n・映画鑑賞やピアノ教室、工作などさまざまなレクリエーション活動を指導します。\n    また、身体機能の改善や減退の防止を目的に、機能訓練を行います。\n\n■ 15:00 - 16:00\n・おやつ：介助が必要な方にはサポートを行いつつ、利用者さんとおやつを食べます。\n\n■ 16:00 - 17:00\n・送迎：スタッフに同⾏して送迎 \n移動介助や乗り降りの介助を⼀緒に⾏って頂きます。\n 帰宅される利⽤者さんを⾃宅まで送り届けます。\n\n■ 17:00・退社: QRコードをスキャンし、退勤処理を⾏います";
const text_belogings_placeholder: string =
    "デイサービスでのお仕事をお願いいたします。\n主な仕事内容は下記の通りです。\n\n・送迎の迎え入れ、送り出しの手伝い \n・入浴解介助、お着替えのお手伝い\n・トイレ介助\n・レクリエーションフォロー";
const text_caution_placeholder: string =
    "1. 以下の症状があるお客様はご出社をお控え下さい。\n・37℃以上の発熱がある⽅、または発熱が続いている⽅\n・⾵邪の症状（発熱、咳、くしゃみ、喉の痛みなど）がある⽅\n・強いだるさ（倦怠感）や息苦しさ（呼吸困難）がある⽅\n・⾝近に新型コロナウイルス感染症の患者や濃厚接触者がいる⽅\n・政府が⼊国制限措置を設けている国・地域から⽇本へ⼊国後、14⽇間経過していな い⽅\n・その他、体調に不安がある⽅\n2. 出社時に検温のご協⼒をお願いします。\n症状によっては、ご出勤をお断りすることがございますので、あらかじ　めご了承ください。\n 3. ご出勤時にお⼿洗いへご案内いたしますので、30秒⼿洗い・うがい・⼿指消毒にご協⼒ください。また、⼿で 　ご⾃⾝の顔（⽬・⼝・⿐など）には触らないようお気を つけください。\n4. 常にマスクをご着⽤ください。(⾷事　時などやむを得ない場合を除く)";
export { 
    transportationData,
    TreatmentData,
    breakTimeData,
    careServicesData,
    caregivingItemsData,
    qualificationData, 
    text_job_description_placeholder,
    text_daily_flow_placeholder,
    text_belogings_placeholder,
    text_caution_placeholder,
}