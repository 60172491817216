import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent  {
  @Input() title = 'confirmCloseTab.title';
  @Input() content_message = 'confirmCloseTab.message';
  @Input() text_btn_accept = 'confirmCloseTab.accept';
  @Input() text_btn_cancel = 'cancel';

  constructor(public activeModal: NgbActiveModal) {}

  confirmExit() {
    this.activeModal.close(true);
  }

  cancelExit() {
    this.activeModal.dismiss(false);
  }
}
