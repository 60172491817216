<form [formGroup]="formBuilder">
  <div class="form-group">
    <label for="title" class="form-label">
      {{ "job_template_title" | translate
      }}<span style="color: #ee5f40">*</span>
    </label>
    <textarea class="form-control" style="height: 48px !important" type="text" formControlName="title"
      placeholder="{{ 'enter_title' | translate }}"
      [ngClass]="{ 'is-invalid': submitted && formControls.title.errors }" maxlength="40"></textarea>
    <div *ngIf="formControls.title.errors" class="form-validation-style">
      <div *ngIf="formControls.title.errors.maxLength">
        {{ "title_warning" | translate }}
      </div>
    </div>
  </div>

  <div class="form-group">
    <label for="title" class="form-label">{{ "title_of_application" | translate
      }}<span style="color: #ee5f40">*</span>{{ "maximum_title_job" | translate }}</label>
    <textarea style="height: 69px !important" class="form-control" type="text" formControlName="recruitment_title"
      placeholder="【北海道札幌市】ショートステイでの介護業務（主に入浴介助）お願いします！"
      [ngClass]="{ 'is-invalid': submitted && formControls.recruitment_title.errors }" maxlength="100"></textarea>
    <div *ngIf="formControls.recruitment_title.errors" class="form-validation-style">
      <div *ngIf="formControls.recruitment_title.errors.maxLength">
        {{ "job_title_warning" | translate }}
      </div>
    </div>
  </div>

  <div class="form-group">
    <label for="select-label" class="form-label">{{ "types_of_care_service" | translate
      }}<span style="color: #ee5f40">*</span></label>
    <ng-select [multiple]="true" [items]="careServices" bindLabel="name" bindValue="name" [clearable]="false"
      [searchable]="false" [ngModelOptions]="{ standalone: true }" class="custom-select custom-select-multi"
      [(ngModel)]="selectedServices" [ngClass]="{
        'custom-invalid':
          !selectedServices.length && submitted
      }">
      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value" *ngFor="let item of items">
          <span class="ng-value-label">{{ item }}</span>
          <span class="ng-value-icon right" style="margin-left: 4px" (click)="clear(item)"
            aria-hidden="true">×</span>
        </div>
        <!-- <div class="ng-value" *ngIf="items.length > 3">
                <span class="ng-value-label">{{items.length - 3}} more....</span>
            </div> -->
      </ng-template>
    </ng-select>
  </div>

  <div class="form-group" style="width: 80%">
    <label for="title" class="form-label">{{ "number_of_applicants" | translate
      }}<span style="color: #ee5f40">*</span></label>
    <input type="number" min="0" class="form-control" formControlName="number_of_applicance"
      (keypress)="numberOnly($event)" onkeydown="javascript: return event.keyCode == 69 ? false : true" [ngClass]="{
        'is-invalid': submitted &&
          formControls.number_of_applicance
      }" />
    <!-- <div *ngIf="formControls.number_of_applicance.errors" class="form-validation-style">
      <div *ngIf="formControls.number_of_applicance.errors.required">....</div>
    </div> -->
  </div>

  <div class="form-group" style="width: 21%">
    <label for="title" class="form-label">{{ "hourly_wages" | translate
      }}<span style="color: #ee5f40">*</span></label>
    <input type="text" class="form-control" formControlName="hourly_wage" (keypress)="numberOnly($event)"
      (input)="numberValidation($event.target.value)" [value]="
        formBuilder?.value?.hourly_wage
          ? formBuilder?.value?.hourly_wage
          : '0'
      " onkeydown="javascript: return event.keyCode == 69 ? false : true" placeholder=" 0 円"
      style="min-width: 90px" [ngClass]="{
        'is-invalid': submitted && formControls?.value?.hourly_wage?.errors
      }" appCursorBeforeYen />
    <!-- <div *ngIf="formControls.daily_wages.errors" class="form-validation-style">
    <div *ngIf="formControls.daily_wages.errors.required">.....</div>
  </div> -->
  </div>
  <h4 *ngIf="
      multipleJobError ==
        '事務局が設定している最低時給金額よりも高い金額を入力してください。'
    " class="multiple-job-error">
    {{ multipleJobError }}
  </h4>

  <div class="form-group" style="width: 21%">
    <label for="title" class="form-label">{{ "application_deadline_day_before_start_date" | translate
      }}<span style="color: #ee5f40"></span></label>
    <input type="text" class="form-control" formControlName="application_deadline_day_before_start_date"
      (keypress)="numberOnly($event)" (input)="
        numberValidation($event.target.value, 'deadline_day')
      " [value]="formBuilder?.value?.application_deadline_day_before_start_date
          ? formBuilder?.value?.application_deadline_day_before_start_date
          : '0'
      " onkeydown="javascript: return event.keyCode == 69 ? false : true" placeholder=" 0 日前"
      style="min-width: 90px" [ngClass]="{
        'is-invalid': submitted &&
          formControls?.value?.application_deadline_day_before_start_date
            ?.errors
      }" />
  </div>

  <div>

    <label for="title" class="form-label">{{ "transportation" | translate
      }}<span style="color: #ee5f40">*</span></label>
    <div class="row" fxLayoutGap="10px" style="margin-bottom: 16px">
      <div class="form-check">
        <input class="form-check-input" type="radio" formControlName="transpor_expence" value="yes"
          name="transpor_expence" id="flexRadioDefault1" (change)="expenseToggle($event)" />
        <label class="form-check-label" style="margin-left: 24px" for="flexRadioDefault1">
          {{ "yes" | translate }}
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" formControlName="transpor_expence" value="no"
          name="transpor_expence" id="flexRadioDefault2" (change)="expenseToggle($event)" />
        <label class="form-check-label" style="margin-left: 24px" for="flexRadioDefault2">
          {{ "no" | translate }}
        </label>
      </div>
    </div>
    <div class="form-group" style="width: 200px" *ngIf="formBuilder?.value?.transpor_expence === 'yes'">
      <label for="title" class="form-label">{{ "allowance" | translate
        }}<span style="color: #ee5f40">*</span></label>
      <input type="text" class="form-control" placeholder="0円" formControlName="payment_amount"
        (keypress)="numberOnly($event)" (input)="
          numberValidation($event.target.value, 'transportation')
        " [value]="
          partTimejobForm?.value?.payment_amount
            ? partTimejobForm?.value?.payment_amount.replace('円', '') +
              '円'
            : '0円'
        " [ngClass]="{
          'is-invalid': submitted && formControls.payment_amount.errors
        }" appCursorBeforeYen />
      <!-- <div *ngIf="formControls.payment_amount.errors" class="form-validation-style">
      <div *ngIf="formControls.payment_amount.errors.required">.....</div>
    </div> -->
    </div>
  </div>

  <div class="form-group">
    <label for="description" class="form-label mt-2">
      {{ "job_description" | translate
      }}<span style="color: #ee5f40">*</span>
      {{ "job_description_2" | translate }}
    </label>
    <textarea class="form-control" type="text" formControlName="description"
      placeholder="{{ 'enter_description' | translate }}" [ngClass]="{
        'is-invalid': submitted && formControls.description.errors
      }" maxlength="1000"></textarea>
    <div *ngIf="formControls.description.errors" class="form-validation-style">
      <div *ngIf="formControls.description.errors.maxLength">
        {{ "job_description_warning" | translate }}
      </div>
    </div>
  </div>

  <div class="form-group">
    <label for="title" class="form-label">{{ "assistance_items" | translate
      }}<span style="color: #ee5f40">*</span>{{ "assistance_items_2" | translate }}</label>
    <ng-select [multiple]="true" [items]="caregivingItems" bindLabel="name" bindValue="name" [clearable]="false"
      [searchable]="false" [(ngModel)]="assistanceItems" [maxSelectedItems]="10"
      class="custom-select custom-select-multi" [ngModelOptions]="{ standalone: true }" [ngClass]="{
        'custom-invalid':
          !assistanceItems.length && submitted
      }" [ngClass]="{
        'custom-invalid': assistanceItems.length > 10
      }">
      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value" *ngFor="let item of items">
          <span class="ng-value-label">{{ item }}</span>
          <span class="ng-value-icon right" style="margin-left: 4px" (click)="clear(item)"
            aria-hidden="true">×</span>
        </div>
      </ng-template>
    </ng-select>
    <div *ngIf="assistanceItems.length > 10" class="form-validation-style">
      <div *ngIf="assistanceItems.length > 10">
        最大10項目まで選択できます
      </div>
    </div>
  </div>

  <div class="form-group">
    <label for="title" class="form-label">{{ "daily_flow" | translate }}<span style="color: #ee5f40">*</span>{{
      "daily_flow_2" | translate }}</label>
    <textarea class="form-control" formControlName="work_flow" maxlength="600"
      [placeholder]="daily_flow_placeholder" [ngClass]="{
        'is-invalid': submitted && formControls.work_flow.errors
      }"></textarea>
    <div *ngIf="formControls.work_flow.errors" class="form-validation-style">
      <div *ngIf="formControls.work_flow.errors.maxLength">
        {{"work_flow_warning" | translate}}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label for="title" class="form-label">{{ "treatment" | translate
      }}<span style="color: #ee5f40">*</span></label>
    <ng-select [multiple]="true" [items]="Treatment" bindLabel="name" bindValue="name" [clearable]="false"
      [searchable]="false" [(ngModel)]="selectedTreantment" [ngModelOptions]="{ standalone: true }"
      class="custom-select custom-select-multi" [ngClass]="{
        'custom-invalid':
          !selectedTreantment.length && submitted
      }">
      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value" *ngFor="let item of items">
          <span class="ng-value-label">{{ item }}</span>
          <span class="ng-value-icon right" style="margin-left: 4px" (click)="clear(item)"
            aria-hidden="true">×</span>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div class="form-group">
    <label for="title" class="form-label">{{ "application_requirements_qualification" | translate
      }}<span style="color: #ee5f40">*</span>{{ "application_requirements_qualification_2" | translate }}</label>
    <ng-select [multiple]="true" [items]="qualification" bindLabel="name" bindValue="name" [clearable]="false"
      [searchable]="false" [(ngModel)]="selectedQualification" class="custom-select custom-select-multi"
      [ngModelOptions]="{ standalone: true }" [ngClass]="{
        'custom-invalid':
          !selectedQualification.length && submitted
      }">
      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value" *ngFor="let item of items">
          <span class="ng-value-label">{{ item }}</span>
          <span class="ng-value-icon right" style="margin-left: 4px" (click)="clear(item)"
            aria-hidden="true">×</span>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div class="form-group">
    <label class="form-label" for="">{{ "belogings" | translate }}<span style="color: #ee5f40">*</span>{{
      "belogings_2" | translate }}</label>
    <textarea class="form-control" maxlength="150" formControlName="what_to_bring"
      [placeholder]="belogings_placeholder" [ngClass]="{
        'is-invalid': submitted &&
          formControls.what_to_bring.errors
      }"></textarea>
    <div *ngIf="formControls.what_to_bring.errors" class="form-validation-style">
      <div *ngIf="formControls.what_to_bring.errors.maxLength">
        300文字以内で入力してください
      </div>
    </div>
  </div>

  <div class="form-group" style="width: 80%">
    <label for="title" class="form-label">{{ "means_of_transportation" | translate
      }}<span style="color: #ee5f40">*</span>{{ "means_of_transportation_2" | translate }}</label>
    <ng-select [multiple]="true" [items]="transportation" bindLabel="name" bindValue="name" [clearable]="false"
      [searchable]="false" [(ngModel)]="selectedTransportation" class="custom-select custom-select-multi"
      [ngModelOptions]="{ standalone: true }" [ngClass]="{
        'custom-invalid':
          !selectedTransportation.length && submitted
      }">
      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value" *ngFor="let item of items">
          <span class="ng-value-label">{{ item }}</span>
          <span class="ng-value-icon right" style="margin-left: 4px" (click)="clear(item)"
            aria-hidden="true">×</span>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div class="form-group">
    <label class="form-label" for="">{{ "caution" | translate }}<span style="color: #ee5f40">*</span>{{
      "caution_2" | translate }}</label>
    <textarea class="form-control" maxlength="600" formControlName="caution" [placeholder]="caution_placeholder"
      [ngClass]="{
        'is-invalid': submitted && formControls.caution.errors
      }"></textarea>
    <div *ngIf="formControls.caution.errors" class="form-validation-style">
      <div *ngIf="formControls.caution.errors.maxLength">
        {{"caution_warning" | translate}}
      </div>
    </div>
  </div>
  <div>
    <label class="form-label">{{ "measures_to_prevent_passive_smoking" | translate
      }}<span style="color: #ee5f40">*</span></label>
    <div class="row" fxLayoutGap="10px">
      <div class="form-check radio-button-resize">
        <input class="form-check-input" type="radio" name="smokking_condition" id="RadioDefault1"
          formControlName="smokking_condition" value="屋内禁煙" />
        <label class="form-check-label form-label-radio" for="RadioDefault1">
          {{ "no_smoking_in_the_house" | translate }}
        </label>
      </div>
      <div class="form-check radio-button-resize">
        <input class="form-check-input" type="radio" formControlName="smokking_condition" value="屋内禁煙可（ただし禁煙室に限る）"
          name="smokking_condition" id="flexRadioDefault2" checked />
        <label class="form-check-label form-label-radio" for="flexRadioDefault2">
          {{ "indoor_non_smoking_is_permitted" | translate }}
        </label>
      </div>
      <div class="form-check radio-button-resize">
        <input class="form-check-input" type="radio" formControlName="smokking_condition" value="屋内禁煙可"
          name="smokking_condition" id="flexRadioDefault3" checked />
        <label class="form-check-label form-label-radio" for="flexRadioDefault3">
          {{ "no_smoking_in_the_house_can_be" | translate }}
        </label>
      </div>
    </div>
  </div>
  <h4 class="section-text pt-1">
    {{
    "the_selections_you_make_will_be_included_in_the_notice_of_working_conditions"
    | translate
    }}
  </h4>
  <h4 class="text-to-be-posted">
    {{ "text_to_be_posted_under_the_part_time_job_openings_1" | translate
    }}<br />{{
    "text_to_be_posted_under_the_part_time_job_openings_2" | translate
    }}
  </h4>

  <button type="button" style="width: 77px; padding-left: 10px; padding-right: 10px"
    class="btn btn-sm btn-primary" rippleEffect (click)="submit()" [disabled]="!formBuilder.valid">
    {{ submitTitle | translate }}
  </button>
</form>