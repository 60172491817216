<div *ngIf="horizontalMenu" id="hideShow" style="margin-left: 20px">
  <div>
    <img
      src="/assets/icons/kaigo-logo.svg"
      alt=""
      style="width: 30px; height: 30px"
    />
  </div>
  <div
    [ngClass]="[
      coreConfig.layout.navbar.customBackgroundColor === true
        ? coreConfig.layout.navbar.background +
          ' ' +
          coreConfig.layout.navbar.type +
          ' ' +
          coreConfig.layout.navbar.backgroundColor
        : coreConfig.layout.navbar.background +
          ' ' +
          coreConfig.layout.navbar.type,
      coreConfig.layout.navbar.type === 'floating-nav' ? 'container-xxl' : ''
    ]"
    class="header-navbar navbar-horizontal menu-border"
    style="
      background-color: transparent;
      margin-top: 10px;
      padding-left: 80px;
      padding-top: 0px;
    "
  >
    <ul
      class="nav navbar-nav"
      id="main-menu-navigation"
      layout="horizontal"
      core-menu
    ></ul>
  </div>
</div>
<div class="navbar-container d-flex" style="padding: 0">
  <div class="bookmark-wrapper d-flex align-items-center">
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
  </div>
  <ul class="nav navbar-nav align-items-center ml-auto">
    <div *ngIf="!env?.production">
      <span
        (click)="changeLang('en')"
        [ngClass]="{ 'translate-text-selected': lang == 'en' }"
        class="translate-text user-name"
        >English</span
      ><span> / </span
      ><span
        (click)="changeLang('ja')"
        [ngClass]="{ 'translate-text-selected': lang == 'ja' }"
        class="translate-text user-name"
        >Japanese</span
      >
    </div>

    <app-navbar-notification></app-navbar-notification>
    <office-selector></office-selector>
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <ng-container *ngIf="this.currentUser">
          <div class="user-nav d-sm-flex d-none"></div>
        </ng-container>
      </a>
    </li>
  </ul>
</div>
<ng-template #menu>
  <div class="horizontal-menu-wrapper">
    <!-- Vertical overlay menu: Visible below large screen only  -->
    <core-sidebar
      name="menu"
      [collapsed]="coreConfig.layout.menu.collapsed"
      collapsibleSidebar="bs-gt-xl"
      *ngIf="!coreConfig.layout.menu.hidden"
      class="main-menu menu-fixed menu-light menu-accordio menu-shadow d-xl-none"
      overlayClass="sidenav-overlay"
      [ngClass]="[coreConfig.layout.menu.collapsed === true ? '' : 'expanded']"
    >
      <app-menu menuType="vertical-menu" class="vertical-menu"></app-menu>
    </core-sidebar>
  </div>
</ng-template>
