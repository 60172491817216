export const ApiConstant = Object.freeze({
  logIn: "auth/login",
  setPassWord: "careProvider/provider-registeration",
  varifyToken: "careProvider/verify-invitation-token",
  profile: "auth/profile",
  forgotPassword: "auth/forgot-password",
  resetPassword: "auth/reset-password",
  updateProfile: "careProvider/edit-careProvider",
  fileUploadRequest: "auth/profile-upload-request",
  createPartTimeProject: "careProvider/create-parttime-job",
  temporarySavePartTimeProject: "careProvider/temporary-save-part-time-job",
  getJobList: "careProvider/parttime-jobList",
  prefectureList: "admin/prefecture-List",
  updateLocation: "careProvider/location",
  editAccessOverview: "careProvider/edit-access-overview",
  updateProfilePhoto: "careProvider/office-photo",
  createFulltimeJob: "careProvider/create-fulltime-job",
  fulltimeUpload: "auth/file-upload-request",
  partimeJobDetails: "careProvider/parttime-job-detail",
  getPartimeJobDetail: "careProvider/parttime-job-detail",
  withdrawPartimeJob: "careProvider/withdraw-job",
  editPartimeJob: "careProvider/edit-parttime-job",
  ApplicantsList: "careProvider/parttimeJob-applicant-List",
  careWorkerDetail: "careProvider/careWorker",
  acceptRejectCareWorker: "careProvider/accept-reject-applicant",
  fulltimeJobDetail: "careProvider/fulltime-job-byProvider",
  attendanceListUnderJob: "careProvider/attendence-List",
  rewardCorrectionRequestList: "careProvider/reward-correction-requests",
  rewardCorrectionRequestsDetailsAndConfirmation:
    "careProvider/reward-correction-status",
  declareAbsence: "careProvider/declare-applicant-absentism",
  workerReview: "careProvider/review-applicant",
  todayAttendanceList: "careProvider/today-attendence-List",
  attendanceHistory: "careProvider/attendece-history",
  changeEmailAddress: "careProvider/send-otp",
  sendOtp: "careProvider/change-email-address",
  updatePassword: "careProvider/change-password",
  announcementList: "messenger/announcement-list",
  fulltimeJobWithdaw: "careProvider/withdraw-job",
  fulltimeJobEdit: "careProvider/edit-fulltime-job",
  invoiceListTable: "careProvider/invoice-List",
  reviewList: "careProvider/review-list",
  invoiceDetails: "careProvider/invoice-details",
  breakdownInvoice: "careProvider/invoice-details",
  fulltimeJobApplicantList: "careProvider/fulltimeJob-applicant-List",
  chatUpdateOnlineStatus: "messenger/update-onlineStatus",
  jobListBasedOnStatus: "careProvider/parttime-jobList-status",
  notification: "messenger/notification",
  announcementCount: "messenger/announcement-count-list",
  rewardSalaryByTime: "careWorker/salary-by-time",
  fulltimeJobInterviewAcceptance:
    "careProvider/fulltimeJob-InterviewAcceptance-status",
  firebase_refresh_token_api:
    "https://securetoken.googleapis.com/v1/token?key={{fb_api_key}}",
  readNotification: "messenger/read-notification",
  newMessage: "messenger/push-notification",
  reviewQuestions: "careProvider/review-questions",
  calculate_daily_wage: "careProvider/calculate-dailywage",
  worker_list: "careProvider/worker-list",
  invite_worker: "careProvider/invitation",
  withdraw_invite: "careProvider/withdraw-invitation",
  invitation_list: "careProvider/invitation",
});
